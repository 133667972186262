/* Webkit browsers (Chrome, Safari, newer versions of Edge) */
.dark ::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}

.dark ::-webkit-scrollbar-track {
  background: #2d3748; /* Tailwind's gray-800 */
}

.dark ::-webkit-scrollbar-thumb {
  background-color: #4a5568; /* Tailwind's gray-700 */
  border-radius: 6px;
  border: 3px solid #2d3748; /* Creates padding effect */
}

.dark ::-webkit-scrollbar-thumb:hover {
  background-color: #718096; /* Tailwind's gray-600 */
}

/* Firefox */
.dark * {
  scrollbar-color: #4a5568 #2d3748; /* thumb and track color */
  scrollbar-width: thin;
}

/* For Internet Explorer and Edge (older versions) */
.dark * {
  scrollbar-face-color: #4a5568;
  scrollbar-track-color: #2d3748;
  scrollbar-arrow-color: #718096;
  scrollbar-shadow-color: #4a5568;
}

/* Light mode scrollbars (optional) */
::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}

::-webkit-scrollbar-track {
  background: #e2e8f0; /* Tailwind's gray-200 */
}

::-webkit-scrollbar-thumb {
  background-color: #cbd5e0; /* Tailwind's gray-400 */
  border-radius: 6px;
  border: 3px solid #e2e8f0;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #a0aec0; /* Tailwind's gray-500 */
}

* {
  scrollbar-color: #cbd5e0 #e2e8f0;
  scrollbar-width: thin;
}