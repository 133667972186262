@tailwind base;
@tailwind components;
@tailwind utilities;

@keyframes noise {
  0% { transform: translate(0,0) }
  10% { transform: translate(-5%,-5%) }
  20% { transform: translate(-10%,5%) }
  30% { transform: translate(5%,-10%) }
  40% { transform: translate(-5%,15%) }
  50% { transform: translate(-10%,5%) }
  60% { transform: translate(15%,0) }
  70% { transform: translate(0,10%) }
  80% { transform: translate(-15%,0) }
  90% { transform: translate(10%,5%) }
  100% { transform: translate(5%,0) }
}

.bg-noise {
  background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 200 200' xmlns='http://www.w3.org/2000/svg'%3E%3Cfilter id='noiseFilter'%3E%3CfeTurbulence type='fractalNoise' baseFrequency='0.65' numOctaves='3' stitchTiles='stitch'/%3E%3C/filter%3E%3Crect width='100%25' height='100%25' filter='url(%23noiseFilter)' opacity='0.05' fill='%23000000'/%3E%3C/svg%3E");
  background-repeat: repeat;
  background-blend-mode: color-burn;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  scroll-behavior: smooth;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.rc-slider-custom .rc-slider-rail {
  @apply bg-gray-300 dark:bg-gray-700;
}

.rc-slider-custom .rc-slider-track {
  @apply bg-indigo-500;
}

.rc-slider-custom .rc-slider-handle {
  @apply border-indigo-500 bg-white;
}

.rc-slider-custom .rc-slider-handle:hover {
  @apply border-indigo-600;
}

.rc-slider-custom .rc-slider-handle-dragging {
  @apply border-indigo-600 shadow-md;
}

.rc-slider-custom.rc-slider-disabled .rc-slider-rail,
.rc-slider-custom.rc-slider-disabled .rc-slider-track {
  @apply bg-gray-200 dark:bg-gray-600;
}

.rc-slider-custom.rc-slider-disabled .rc-slider-handle {
  @apply border-gray-300 bg-gray-100;
}

.fade-in {
  opacity: 1; /* Start invisible */
  animation: fadeIn 0.5s forwards; /* Fade in over 1 second */
  animation-delay: 3s; /* Delay before starting the animation */
}




.custom-scrollbar-page {
  scrollbar-width: thin;
  scrollbar-color: rgba(0, 0, 0, 0.9) transparent;
}

/* .custom-scrollbar-page::hover {
  scrollbar-color: rgba(255, 255, 255, 0.9) transparent;
} */

.custom-scrollbar-page::-webkit-scrollbar {
  width: 8px;
}

.custom-scrollbar-page::-webkit-scrollbar-track {
  background: transparent;
  width: 12px;
}

.custom-scrollbar-page::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.9);
  border-radius: 4px;
}

